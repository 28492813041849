<template>
    <AppTabLinks :links="list" />
</template>

<script>
    import { mapState } from 'vuex';
    import AppTabLinks from '@/components/AppTabLinks'

    export default {
        components: {
            AppTabLinks
        },
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('discounts', ['entity']),
            isAdmin() {
                return this.user.admin;
            },
            list() {
                return [
                    {
                        title: 'Карточка',
                        url: `/discounts/${this.$route.params.id}/detail`,
                        disabled: !this.$route.params.id
                    },
                    {
                        title: 'Редактировать',
                        url: this.$route.params.id ? `/discounts/${this.$route.params.id}/edit` : '/discounts/edit'
                    },
                    {
                        title: 'Время проведения',
                        url: `/discounts/${this.$route.params.id}/schedules`,
                        disabled: !this.$route.params.id,
                        fill_required: !(this.entity.schedules && this.entity.schedules.length)
                    },
                ]
            }
        },
    }
</script>
