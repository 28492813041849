<template>
    <v-row>
        <v-col>
            <v-card class="pa-4">
                <v-card-title>{{ discount.title }}</v-card-title>
                <v-card-text>
                    <DiscountTabLinks />
                    <v-row class="pl-3 pb-3" />
                    <v-form>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="discount.title"
                                    outlined
                                    label="Заголовок"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-img width="300px" v-if="discount.picture" :src="imageSrc(discount.picture)" class="mb-4" />
                                <v-file-input
                                    label="Картинка"
                                    outlined
                                    prepend-icon="mdi-image"
                                    hide-details
                                    @change="setPicture"
                                />
                                <p class="ma-0 pl-9 text-small">Картинка для списка акций. Рекомендуемый размер: ширина - 450px, высота - 300px. При отсутствии картинки - акция не будет отображаться в списке.</p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-select
                                    v-model="discount.site_id"
                                    label="Сайт"
                                    :items="entities"
                                    item-text="title"
                                    item-value="id"
                                    @change="setEntityId"
                                    outlined
                                    hide-details
                                />
                            </v-col>
                        </v-row>

                        <template v-if="discount.site_id">
                            <v-row>
                                <v-col>
                                    <v-select
                                        v-model="discount.type"
                                        label="Тип"
                                        :items="types"
                                        item-text="label"
                                        item-value="value"
                                        outlined
                                        hide-details
                                    />
                                </v-col>
                            </v-row>

                            <template v-if="discount.type === DISCOUNT_TYPE.PERCENT">
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            v-model="discount.percent"
                                            outlined
                                            label="Размер скидки, %"
                                            hide-details
                                        />
                                    </v-col>
                                </v-row>

                                <v-divider class="my-4"/>

                                <v-row>
                                    <v-col>
                                        <b>Скидка действует</b>
                                        <v-radio-group
                                            v-model="discount.all_menus"
                                            hide-details>
                                            <v-radio
                                                :value="true"
                                                label="На всё меню">
                                            </v-radio>
                                            <v-radio
                                                :value="false"
                                                label="На выбранные позиции">
                                            </v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                                <v-row v-if="!discount.all_menus">
                                    <v-col>
                                        <v-btn
                                            color="primary"
                                            class="mr-3"
                                            @click="openProductList"
                                        >Добавить блюдо</v-btn>
                                        <v-simple-table>
                                            <template v-slot:default>
                                                <tbody>
                                                    <tr v-for="item in filteredProducts" :key="item.id">
                                                        <td>{{ item.title }}</td>
                                                        <td align="right">
                                                            <v-icon color="error" @click="removeProduct(item)">mdi-trash-can</v-icon>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-col>
                                </v-row>
                            </template>
                            <v-divider class="my-4"/>
                            <v-row>
                                <v-col>
                                    <b>Акция применяется</b>
                                    <v-radio-group
                                        v-model="discount.delivery_type"
                                        hide-details>
                                        <v-radio
                                            value="all"
                                            label="На доставку и самовывоз">
                                        </v-radio>
                                        <v-radio
                                            value="delivery"
                                            label="Только на доставку">
                                        </v-radio>
                                        <v-radio
                                            value="pickup"
                                            label="Только на самовывоз">
                                        </v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>

                            <v-divider class="my-4"/>
                        </template>

                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="discount.position"
                                    outlined
                                    label="Позиция"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-switch
                                    v-model="discount.enabled"
                                    label="Активен"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-switch
                                    v-model="discount.for_delivery"
                                    label="Применять в разделе доставки"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-switch
                                    v-model="discount.for_menu"
                                    label="Применять в электронном меню"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pa-4 d-flex justify-space-between">
                    <div>
                        <v-btn
                            color="primary"
                            class="mr-3"
                            @click="save"
                        >Сохранить</v-btn>
                        <v-btn
                            color="light"
                            class="mr-3"
                            @click="goBack"
                        >Отмена</v-btn>
                    </div>
                    <v-btn
                        v-if="isAdmin"
                        color="error"
                        @click="deleteModal = true"
                    >Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>


        <v-dialog
            v-model="productListModal"
            width="800px"
        >
            <v-card>
                <v-card-title class="primary">
                    Выберите блюдо
                </v-card-title>

                <v-card-text>
                    <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                                <tr v-for="item in checkedProducts" :key="item.id">
                                    <td>{{ item.title }}</td>
                                    <td align="right">
                                        <v-icon v-if="item.added" color="error" @click="removeProduct(item)">mdi-trash-can</v-icon>
                                        <v-icon v-else color="primary" @click="addProduct(item)">mdi-plus</v-icon>
                                    </td>
                                    <!-- <td>
                                        <v-icon color="primary" @click="remove(item)">mdi-trash-can</v-icon>
                                    </td> -->
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="light"
                        @click="productListModal = false"
                    >Закрыть</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="deleteModal"
            width="800px"
        >
            <v-card>
                <v-card-title class="error">
                    Подтверждение
                </v-card-title>

                <v-card-text>
                    Вы действительно хотите удалить акцию? Это действие нельзя будет отменить.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                        color="error"
                        @click="remove"
                    >Удалить</v-btn>
                    <v-spacer />
                    <v-btn
                        color="light"
                        @click="deleteModal = false"
                    >Отмена</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import store from '../../store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';
    import DiscountTabLinks from '../../components/discounts/DiscountTabLinks';

    import { imageSrc, translit } from '../../helpers';
    import { DISCOUNT_TYPE, DISCOUNT_TYPE_TITLE } from '../../vars';

    export default {
        name: 'DiscountEdit',

        components: {
            DiscountTabLinks,
        },

        data() {
            return {
                DISCOUNT_TYPE, 
                DISCOUNT_TYPE_TITLE,
                imageSrc,
                discount: {
                    site_id: null,
                    type: DISCOUNT_TYPE.PERCENT,
                    title: '',
                    picture: '',
                    all_menus: true,
                    percent: 0,
                    min_order_price: 0,
                    delivery_type: 'all',
                    for_delivery: true,
                    for_menu: true,
                    position: 0,
                    enabled: true,
                    products: [],
                    addedProducts: [],
                    removedProducts: []
                },
                productListModal: false,
                deleteModal: false,
            };
        },

        computed: {
            ...mapState('auth', ['user']),
            ...mapState('sites', ['entities']),
            ...mapState('products', {
                products: state => state.entities
            }),
            ...mapState('discounts', {
                oldDiscount: state => state.entity
            }),
            isAdmin() {
                return this.user.admin;
            },
            types() {
                const types = [];
                for(const key in DISCOUNT_TYPE) {
                    types.push({
                        value: DISCOUNT_TYPE[key],
                        label: DISCOUNT_TYPE_TITLE[DISCOUNT_TYPE[key]],
                    });
                }
                return types;
            },
            addedProductIds() {
                return this.discount.addedProducts.map(item => item.id);
            },
            checkedProducts() {
                return this.products.map(item => {
                    return {
                        ...item,
                        added: this.addedProductIds.indexOf(item.id) >= 0
                    }
                }).sort((a, b) => a.title < b.title ? -1 : 1);
            },
            filteredProducts() {
                return this.discount.addedProducts.filter(item => item.site_id === this.discount.site_id);
            }
        },

        async mounted() {
            const id = this.$route.params.id;
            await store.dispatch('sites/fetch', {});
            if(id) {
                await this.get(id);
            }
        },

        methods: {
            async setPicture(file) {
                this.discount.picture = await store.dispatch('files/upload', { file });
            },
            async setEntityId() {
                if(this.discount.site_id) {
                    await store.dispatch('products/fetch', { site_id: this.discount.site_id });
                }
            },
            async openProductList() {
                this.productListModal = true;
            },
            async addProduct(product) {
                this.discount.addedProducts.push(product);
            },
            async removeProduct(product) {
                this.discount.addedProducts = this.discount.addedProducts.filter(item => item.id !== product.id);
                if(product.discount_product_id) {
                    this.discount.removedProducts.push(product);
                }
            },
            async get(id) {
                await store.dispatch('discounts/get', {id});
                this.discount = {
                    ...this.oldDiscount,
                    addedProducts: this.oldDiscount.products,
                    removedProducts: [],
                };
                this.discount.all_menus = !!this.discount.all_menus;
                await this.setEntityId();
            },
            async save() {
                // try {
                    store.commit('discounts/SET_ENTITY', this.discount);
                    const { id } = await store.dispatch('discounts/save');
                    for (const product of this.discount.products) {
                        if(product.discount_product_id && product.site_id !== this.discount.site_id) {
                            await store.dispatch('discountProducts/delete', { discount_id: id, id: product.discount_product_id });
                        }
                    }
                    for (const product of this.discount.removedProducts) {
                        if(product.discount_product_id) {
                            await store.dispatch('discountProducts/delete', { discount_id: id, id: product.discount_product_id });
                        }
                    }
                    for (const product of this.discount.addedProducts) {
                        if(!product.discount_product_id && product.site_id === this.discount.site_id) {
                            store.commit('discountProducts/SET_ENTITY', {
                                discount_id: id,
                                product_id: product.id
                            });
                            await store.dispatch('discountProducts/save', { discount_id: id });
                        }
                    }
                    this.$router.push(`/discounts/${ id }/detail`);
                // } catch (error) {
                //     alert('Ошибка сохранения');
                // }
            },
            async remove() {
                const { id } = this.$route.params;
                try {
                    await store.dispatch('discounts/delete', { id });
                    this.$router.push('/discounts');
                } catch (error) {
                    alert('Ошибка удаления');
                }
            },
            goBack() {
                const { id } = this.$route.params;
                if(id) {
                    this.$router.push(`/discounts/${ id }/detail`);
                } else {
                    this.$router.push('/discounts');
                }
            },
        },
    };
</script>
